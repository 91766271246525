.export-columns {
    background-color: #ffffff;
    padding-bottom: 4vh;
    border-radius: 10px;
    border: 1px solid rgb(236, 237, 240);
  }
  .export-columns-heading {
    /* padding-left: 2em; */
    padding: 4vh 0vw;
  }
  
  .export-columns-heading > h1 {
    /* font-size: 1.25em;
    font-weight: 700; */
    padding-left: 1.5rem;
    font-size: 1.5em;
    /* margin: 2vh 2.5vw; */
    margin: 1vh 0vw;
    /* margin-bottom: 4vh; */
  }
  .export-columns-content {
    background-color: "red";
  }
  .export-columns-content-row {
    display: flex;
    align-items: center;
    /* background-color: aquamarine; */
    padding: 2vh 0px;
  }
  .export-columns-content-row-content > p {
    font-size: 1.2em;
    color: #262626;
    font-weight: 400;
  }
  .outer-view {
    display: flex;
    /* border:1px solid green; */
    justify-content: flex-start;
    padding: 1.6em 1.5em 0em 1.5em;
  }
  .view-more {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
    /* padding:1.5em 0em 0em 0em; */
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 0.4px;
    cursor: pointer;
    /* color:rgb(64,64,64); */
    color: "rgb(76,76,76)";
    /* border:1px solid black; */
  }
  
  .filter-dropdowm {
    position: absolute;
    top: 2em;
    left: 0;
    width: 100%;
    color: rgb(122, 122, 122);
    font-size: 1.1em;
    font-weight: 300;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    /* border-top: none; */
    z-index: 1000;
  }
  
  .e-grid .e-dialog.e-checkboxfilter {
    position: absolute;
    background-color: white;
    border: 1px solid rgb(221, 221, 221);
    padding: 10px;
    border-radius: 4px;
    color: gray;
  }
  .e-grid .e-dialog.e-checkboxfilter .e-icons:not(.e-btn-icon) {
    color: gray;
    padding-right: 10px;
    font-size: larger;
    height: 30px;
  }
  .e-grid .e-dialog.e-checkboxfilter [class^="e-"] input {
    box-sizing: border-box;
    border: 1px solid rgb(173, 173, 173);
    padding: 2px 0px;
    padding-left: 10px;
    border-radius: 4px;
    /* background-color: orange; */
  }
  /* .e-grid [class^="e-"] input ::placeholder {
    color: red;
  } */
  
  
  .checkbox{
    padding: 0px !important;
  }
  
  .e-grid [class^="e-"] button {
    box-sizing: border-box;
    padding: 0.4rem 1.5rem;
    margin-left: 20px;
    color: white;
    background-color: #2081e3;
    border: none;
    border-radius: 3px;
    font-size: larger;
    font-weight: 500;
    margin-top: 10px;
  }
  .e-grid .e-rowcell .e-checkbox-wrapper, .e-grid .e-rowcell .e-css.e-checkbox-wrapper {
      -webkit-user-select: auto;
      height: 20px;
      line-height: 20px;
      position: relative;
      top: 0;
      user-select: auto;
      /* padding: 1rem !important; */
  }
  .e-grid .e-checkboxfiltertext {
      width: 150px;
      word-break: normal;
      margin-left: 1rem;
  }
  .e-grid .e-icons:not(.e-btn-icon) {
    color: transparent;
  }


  .e-grid .e-icon-filter::before, .e-grid .e-icon-filter.e-filtered::before, .e-grid-menu .e-icon-filter::before, .e-grid-menu .e-icon-filter.e-filtered::before {
    content: "\e7ee";
    color: #2081E2;
    font-size: 1.2em;
    /* padding-right: 1rem; */
  }


  /* input[type="checkbox"]::before {
    content: "";
    width: 1rem;
    height: 1rem;
    clip-path: polygon( 10% 59%, 33% 87%, 83% 40%, 79% 18%, 34% 64%, 15% 47% );
    transform: scale(0);
    background-color: orange;
} */

.checkboxCustom::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  clip-path: polygon(10% 59%, 33% 87%, 83% 40%, 79% 18%, 34% 64%, 15% 47%);
  transform: scale(0);
  background-color: white !important;
}

.checkbox1::before{
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  clip-path: polygon(10% 59%, 33% 87%, 83% 40%, 79% 18%, 34% 64%, 15% 47%);
  transform: scale(0);
  background-color: orange !important;
}

.fuse{
  ::-webkit-scrollbar {display:none;}
}
.fuse{
  /* overflow: hidden !important; */
}
.e-grid .e-gridheader thead .e-icons:not(.e-check):not(.e-stop) {
  padding-right: 25px !important;
}




  