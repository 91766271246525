.segment{
    /* background-color: red; */
    /* padding: 10px 0px; */
}
.segment-no-data{
    border-radius: 10px;
    height: 30vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(233, 233, 233);
}
.segment-no-data>p{
    font-size: 1em;
    font-weight: 400;
    margin: 5px 0px;
}

.segment-no-data>button{
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #2081E3;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 0.9em;
    font-weight: 600;
    cursor: pointer;
}
.segment-content{
    font-size: 1.1em;
    border-radius: 10px;
    background-color: #ffffff;
    border:1px solid #E5E7EB;
    
}
.segment-content-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 2vw;
}
.segment-content-1-1{
    display: flex;
    /* background-color: #4DC36B; */
    align-items: center;
    justify-content: center;
}
.segment-content-1-1>input{
    border:2px solid #D1D5DB;
    height: 17px;
    width:17px;
}
.segment-content-1-1>p{
    /* color:#262626;
    font-weight: 600;
    font-size: 0.875em;
    letter-spacing: 0.2px; */
    font-weight: 600;
    color: rgb(65, 65, 65);
    font-size: 0.88em;
    letter-spacing: 0.4px;
    
}
.segment-content-1-2-1{
    display: flex;
}
.segment-content-1-2-1>input{
    padding: 10px;
    outline: none;
    /* border-radius: 4px; */
    border: 1px solid #E5E7EB;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.segment-content-1-2-1>input:focus{
    border:1px solid black;
}
.segment-content-1-2-1>div{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5E7EB;
    padding: 0px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.segment-content-1-2-2{
    width: 13vw;
    border: 1px solid #E5E7EB;
    /* background-color: #4DC36B; */
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    position: relative;
}
.segment-content-1-2-2>p{
 
    color: #4C4E4C;
}
.segment-content-1-2-2>div>div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.segment-content-1-2-2-dropdown{
    z-index: 1;
    width: 100%;
    right: 0px;
    position: absolute;
    top: 40px;
    /* box-shadow: ; */
    border-radius: 4px;
    border:1px solid #E5E7EB;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.06);


    /* box-shadow: 1px 3px 8px 2px #d8d8d8, */

    /* zIndex:1,width:"100%",right:"0px",position:"absolute",top:"40px",backgroundColor:"red" */
}
.segment-content-1-2-2-dropdown>ul{
    padding: 0px;
    list-style: none;
    background-color: #ffffff;
    /* background-color: #4DC36B; */
    /* box-shadow: 2px 2px 15px 2px rgb(146, 142, 142); */
}
.segment-content-1-2-2-dropdown>ul>li{
    padding: 10px 20px;
    font-size: 0.9em;
    font-weight: 500;
    color: #404040B2;
   
}
.segment-content-1-2-2-dropdown>ul>li:hover{
    background-color: #f3f1f1;
    cursor: pointer;

}
.segment-content-1-2-3{
    background-color: #E5E7EB;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.segment-content-1-2{
    width: 35vw;
    display: flex;
    /* background-color: #4DC36B; */
    justify-content: space-between;
}
.segment-content-3{
    border-top: 1px solid #E5E7EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 2vw;
}
.segment-content-3-2>div>p{
    font-size: 0.8em;
    /* width: 20vw; */
    /* background-color: #4DC36B; */
}
.segment-content-3-2>button{
    margin-left: 10px;
    padding: 10px 15px;
    background-color: #F2F2F5;
    border: none;
    border-radius: 4px;
    /* font-size: 0.8em; */
    font-weight: 500;
    color: #404040;
    letter-spacing: 0.3px;

}

.segment-heading{
    /* font-size: 1.3em; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.segment-heading-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.segmet-heading-right-1{
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #E5E7EB;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.segment-heading-right-1>p{
    font-size: 16px;
}
.segment-heading-right-2{
    cursor: pointer;
    padding: 10px 20px;
    background-color: #2081E3;
    border-radius: 4px;
    color: #ffffff;
}
.segment-heading-right-2>p{
    font-size: 16px;
}
.segment-content-3-1>p{
    font-weight: 400;
    font-size:0.875em;
    color:rgba(64, 64, 64, 1)
}
.segment-content-3-1>p>span{
    font-weight: 600;
    /* font-size:1em; */
    /* color: #404040; */
}