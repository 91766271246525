.my-profile{
    background-color: #ffffff;
    padding:5vh 2vw ;
    /* padding-bottom: 2vh !important; */
    /* padding-bottom: 0px; */
    border-radius: 10px;
    
}
.my-profile-heading>h1{
    /* font-size: 1.3em;
    font-weight: 700; */
    /* padding-left: 2rem; */
    font-size: 1.5em;
    /* margin: 2vh 2.5vw; */
    /* margin: 1vh 0vw; */
    color: #262626;
    /* font-style: Inter; */
    font-family: Inter;
}
.my-profile-content-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.my-profile-content-1-1{
    width: 48%;
    display: flex;
    flex-direction: column;
    margin: 1vh 0px;
    /* background-color: aquamarine; */
}
.my-profile-content-1-1>p{
    font-size: 0.95em;
    margin-bottom: 2.2vh;
    color: #262626;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.my-profile-content-1-1>input{
    padding: 10px;
    outline: none;
    border: 1px solid #E5E7EB;
    
    border-radius: 4px;
    cursor: pointer;
}
.my-profile-content-2{
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    margin: 3vh 0px;
}
.my-profile-content-2-1>p{
    font-size: 0.95em;
    margin-bottom: 2.2vh;
    color: #262626;
    font-weight: 500;
    letter-spacing: 0.5px;
    /* background-color: aqua; */
}
.my-profile-content-2-1>input{
    padding: 10px;
    width: 100%;
    outline: none;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    cursor: pointer;
}
.my-profile-content-2-1>input:focus{
    border: 1px solid #262626;

}
.my-profile-content-2-1>div>input{
    padding: 10px;
    width: 100%;
    outline: none;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    cursor: pointer;
}
.my-profile-content-2-1>div>input:focus{
    border: 1px solid #262626;

}
/* .my-profile-content-2-1>input:hover{
    border: 1px solid #262626;
} */

.my-profile-content-footer{
    /* padding: 2vh 0px; */
    padding-top: 6vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 4vh 0vh !important;
    border-top: 1px solid #EBEDF0;

    
}
.my-profile-content-footer>div>p{
    background-color: #2081E3;
    font-weight:550;
    font-size:14px;
    padding:2vh 2vw !important;
    border-radius: 4px;
    cursor: pointer;
}