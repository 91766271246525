.history>h1{
    /* background-color: red; */
    /* padding: 10px 20px; */
}
.history-content{
    /* height: 30vh; */
    background-color: #ffffff;
    padding: 0px;
    border-radius: 8px;
    border:1px solid #E5E7EB;

}


.history-content-footer{
    /* background-color: aqua; */
    padding: 5vh 2.2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px solid #E5E7EB; */
}
.history-content-footer>div>button{
    padding: 10px 15px;
    margin-left: 10px;
    /* background-color: #F2F2F5; */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 1.1em;
    font-weight: 500;
    color: #404040;
}
.history-content-footer>div>p{
    /* color: rgb(80, 80, 80);
    font-size: 1.1em;
    font-weight: 400; */
    font-weight: 400;
    font-size:0.9em;
    color:rgba(64, 64, 64, 1)
}
.history-content-footer>div>p>span{
    /* color: rgb(80, 80, 80);
    font-size: 0.9em;
    font-weight:600; */
    font-weight: 600;
}

.history-no-data{
    height: 30vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-no-data>p{
    font-size: 1.2em;
    font-weight: 500;
}
/* .history-heading{
    font-size: 1.3em;
} */