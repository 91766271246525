.main-notification-container{
    background-color: #ffffff;
    padding-top:5vh;
    

    border-radius: 10px;
    color:rgb(38,38,38);
   
}
.notification-heading{
    padding-left: 2em;
}
.notification-heading>h1{
    font-size: 1.5em;
    font-weight: 700;
    color:black
    
    
}
.notifictaion-items{
    /* border:1px solid black; */
    border-bottom:1px solid  rgb(235,237,240);
    padding: 1em 0em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:0.6em;
    padding-left: 2em;
    cursor: pointer;

}
.notifictaion-items:hover{
    background-color: rgb(249, 250, 252);
}
.item-one{
    font-size: 0.92em;
    font-weight: 600;
    color:rgb(52,52,52);
    text-align: left;
  
 
}
.item-two{
    color:  #989898;
    font-size: 0.8em;
    font-weight: 600;
    text-align: left;
    /* background-color: red; */
}

.notification-btn-section{
    /* border:1px solid black; */
    padding:1.6em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.notification-btn-section>p{
  color:rgb(88,88,88);
  font-size: 1.1em;
  font-weight: 400;
  
}

#page-num >span{
     padding:0em 0.3em;
     color:rgb(64,64,64);
     font-weight: 600;
    
}
.prev-btn{
    border:none;
    padding:0.7em 1.3em;
    /* background-color: rgb(242,243,245); */
    /* color:rgb(164,164,165); */
    background-color: rgb(229,231,235);
    color:rgb(88,88,88);
    font-size: 1.2em;
    font-weight: 600;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;

}
.next-btn{
    margin-left: 0.4em;
    border:none;
    padding:0.7em 1.3em;
    background-color: rgb(229,231,235);
    font-size: 1.2em;
    font-weight: 600;
    color:rgb(88,88,88);
    border-radius: 6px;
    text-align: center;
    cursor: pointer;

}